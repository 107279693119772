export class Shifts {
    shiftList = [{
        id: 1,
        fullName: "T-S 12:00am to 8:00am (WDAON)",
        shortName: "WDAON",
        program: "Res",
        programDeptMappingIds: [2, 4],
        timeRange: "12:00am to 8:00am",
        startTime: "12:00 am",
        endTime: "8:00 am",
        shiftHours: 8,
        weekHours: 40,
        weekDays: "T-S",
        isActive: true
    },
    {
        id: 2,
        fullName: "M-F 8:00am to 4:00pm (DAY)",
        shortName: "DAY",
        program: "Day",
        programDeptMappingIds: [1, 3],
        timeRange: "8:00am to 4:00pm",
        startTime: "8:00 am",
        endTime: "4:00 pm",
        shiftHours: 8,
        weekHours: 40,
        weekDays: "M-F",
        isActive: true
    },
    {
        id: 3,
        fullName: "M-F 4:00pm to 12:00am (WDE)",
        shortName: "WDE",
        program: "Res",
        programDeptMappingIds: [2, 4],
        timeRange: "4:00pm to 12:00am",
        startTime: "4:00 pm",
        endTime: "12:00 am",
        shiftHours: 8,
        weekHours: 40,
        weekDays: "M-F",
        isActive: true
    },
    {
        id: 4,
        fullName: "SU-M 12:00am to 8:00am (WEAON)",
        shortName: "WEAON",
        program: "Res",
        programDeptMappingIds: [2, 4],
        timeRange: "12:00am to 8:00am",
        startTime: "12:00 am",
        endTime: "8:00 am",
        shiftHours: 8,
        weekHours: 16,
        weekDays: "SU-M",
        isActive: true
    },
    {
        id: 5,
        fullName: "S-SU 8:00am to 12:00am (WED)",
        shortName: "WED",
        program: "Res",
        programDeptMappingIds: [2, 4],
        timeRange: "8:00am to 12:00am",
        startTime: "8:00 am",
        endTime: "12:00 am",
        shiftHours: 16,
        weekHours: 32,
        weekDays: "S-SU",
        isActive: true
    },
    {
        id: 6,
        fullName: "M-F 8:00am to 4:00pm (ILOD)",
        shortName: "ILOD",
        program: "Res",
        programDeptMappingIds: [2, 4],
        timeRange: "8:00am to 4:00pm",
        startTime: "8:00 am",
        endTime: "4:00 pm",
        shiftHours: 8,
        weekHours: 40,
        weekDays: "M-F",
        isActive: true
    }
    ]
}
