import { AppComponentBase } from '@shared/common/app-component-base';
import { ViewChild, Component, Injector, EventEmitter, Output, Input, ChangeDetectorRef, ElementRef, SimpleChanges } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap";
import { NotifyService } from '@abp/notify/notify.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
    CommonServiceProxy, ClientInfoComponentDto,
    CreateOrEditProgramEnrollmentDto,
    PatientsServiceProxy, ServiceNotesServiceProxy, ServicesServiceProxy, CreateOrEditServiceNotesDto,
    GetAllServiecsForDropdownListDto, ServiceEnrollmentsServiceProxy, ServiceEnrollmentDto
} from '@shared/service-proxies/service-proxies';
import { Validators, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { finalize, timeout } from "rxjs/operators";
import { CommonFooterComponent, FooterobjectDto } from '../CommonFooter/common-footer-componet';
import { Shifts } from '@shared/common/shifts';
import { AudioConfig, ResultReason, SpeechConfig, SpeechRecognizer } from 'microsoft-cognitiveservices-speech-sdk';
import SiriWave from "siriwave";

@Component({
    selector: 'create-edit-service-note',
    templateUrl: './create-edit-service-notes.component.html',
    styleUrls: ['./create-edit-service-notes.component.css']
})
export class CreateEditServiceNotesComponent extends AppComponentBase {

    @Input() data = new CreateOrEditServiceNotesDto();
    @Output() onNewNoteSave = new EventEmitter<any>();
    @Output() onNoteCancel = new EventEmitter<any>();

    @ViewChild('commonfootermodal', { static: true }) commonfootermodalchild: CommonFooterComponent;
    _footerobj: FooterobjectDto = new FooterobjectDto();

    today: Date;
    clientInfo: ClientInfoComponentDto = new ClientInfoComponentDto();//data will come from parent

    @ViewChild('noteForm', { static: true }) form;
    @ViewChild('shift', { static: true }) shift;
    @ViewChild('soundwave', { static: false }) soundwave: ElementRef;

    displayDescriptionInDialog: boolean = false;
    displayPCPGoalsInDialog: boolean = false;
    isLoading = false;
    clientData: any[] = [];
    blocked: boolean = false;
    pheight: string;
    weight: number;
    serviceNoteId: number = 0;
    location: string = "";
    serviceNote: CreateOrEditServiceNotesDto = new CreateOrEditServiceNotesDto();
    programEnrollList: CreateOrEditProgramEnrollmentDto[] = new Array();

    servicesList: GetAllServiecsForDropdownListDto[] = new Array();
    servicesListBackup: GetAllServiecsForDropdownListDto[] = new Array();

    selectedService: GetAllServiecsForDropdownListDto = null;

    enrolledServicesList: ServiceEnrollmentDto[] = new Array();
    selectedEnrolledService: ServiceEnrollmentDto = null;


    showStartOrEndTime = true;
    private sub: any;
    startTime: any; //serviceNote.noteStartTime
    endTime: any; //serviceNote.noteEndTime
    maxStartDate = new Date();
    minStartDate = new Date();
    maxEndDate = new Date();
    totalHours: number = 0;
    mode: string = "new";
    _view: boolean = false;
    selectedProgram: CreateOrEditProgramEnrollmentDto = null;
    siteOccupancy: string = "";
    programLoading: boolean = false;
    servicesLoading: boolean = false;
    isError: boolean = false;
    errorDescription: string = "";
    programEnrollmentId: number = 0;

    shifts: Shifts = new Shifts();
    shiftList: any;
    selectedShift: any = null;
    shiftStartTime: string = "";
    noEnrolledServices: boolean = false;

    readonly _RETAINER_SERVICE_ID: string = "CLGHR";
    readonly _DAY_PROGRAM_ID: string = "Day";

    constructor(
        injector: Injector,
        private _commonServiceProxy: CommonServiceProxy,
        private cd: ChangeDetectorRef,
        private route: ActivatedRoute,
        private _notifyService: NotifyService,
        private _patientsServiceProxy: PatientsServiceProxy,
        private _router: Router,
        private _serviceNotesServiceProxy: ServiceNotesServiceProxy,
        private _ltssServices: ServicesServiceProxy,
        private _serviceEnrollmentsServiceProxy: ServiceEnrollmentsServiceProxy
    ) {
        super(injector);
        (moment as any).fn.toString = function () { return this.format("L"); };
        moment.locale("en");
        this.today = new Date();

        this.sub = this.route.params.subscribe(params => {
            if (params) {
                this.serviceNoteId = params.serviceNoteId;
                this.mode = params.mode;
            }
        });
        this.mode == 'view' ? this._view = true : this._view = false;
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes.data.currentValue){
            this.mode = 'dialog';
            this.serviceNote = changes.data.currentValue;
            //this.serviceNote.id = 0;
            if (this.serviceNote.id == 0) {
                this.initializeNewNewNoteInfo(this.serviceNote.id);
                this.programEnrollmentId = this.serviceNote.programEnrollmentId;
                this.getPCPGoalsByClientIdAndProgramDeptMappingId();
            }
            else {
                this.mode = "edit";
                this.serviceNoteId = this.serviceNote.id;
                this.getServiceNoteById();
            }

            this.selectShiftBySerciceCodeAndNoteDate(this.serviceNote.serviceCode, this.serviceNote.noteDate);
        }
    }

    selectShiftBySerciceCodeAndNoteDate(serviceCode: string, noteDate: moment.Moment) {
        if (serviceCode.indexOf("CLGH") > -1) {
            this.selectedShift = this.shifts.shiftList[2];
            if (noteDate.day() == 0 || noteDate.day() == 6) {//Satu and Sunday
                this.selectedShift = this.shifts.shiftList[4];
            }
            this.onShiftChange(this.selectedShift);
        }
        //3 saturday, and 4 is Sunday
        
    }

    ngOnInit(): void {
        debugger;
        this.shiftList = this.shifts.shiftList;
        this.getAllServicesForDropDownList();
        this.maxEndDate.setHours(this.maxEndDate.getHours() + 16);
        this.minStartDate.setDate(this.minStartDate.getDate() - 400);
        if (this.mode == "new") {
            this.initializeNewNewNoteInfo(0);
        } else {
            if (this.serviceNoteId) {
                this.getServiceNoteById();
            }
        }
    }

    initializeNewNewNoteInfo(noteId: number) {
        this.serviceNote.serviceDescription = "";
        this.serviceNote.clientStatus = "Present";
        if (this.serviceNote.serviceId > 0) {
            //this.serviceNote = result;
            this.clientInfo.clientName = this.serviceNote.patientName;
            this.clientInfo.clientID = this.serviceNote.patientsId;
            this.getProgramsByClientId();
            if(this.serviceNote.serviceStartDateTime){
                if (this.serviceNote.serviceStartDateTime.year() != 0) {
                    this.startTime = new Date(moment(this.serviceNote.serviceStartDateTime).format('YYYY-MM-DDTHH:mm:ss'));
                }
            }
            if (this.serviceNote.serviceEndDateTime){
                if (this.serviceNote.serviceEndDateTime.year() != 0) {
                    this.endTime = new Date(moment(this.serviceNote.serviceEndDateTime).format('YYYY-MM-DDTHH:mm:ss'));
                }
            }

            //this.totalHours = moment(this.endTime).diff(moment(this.startTime), 'minutes') / 60;
            if(this.serviceNote.shiftId){
                this.selectShift(this.serviceNote.shiftId);
            }
            this.updateFooter();
        } else {
            this.clientInfo.clientName = "";
            this.serviceNote.programDeptMappingId = 0;
            this.serviceNote.serviceId = 0;
            this.serviceNote.id = noteId;
            this.serviceNote.shiftId = 0;
            this.serviceNote.siteId = 0;
            this.serviceNote.siteName = "";
            this.serviceNote.siteOccupancy = 0;
            this.serviceNote.patientsId = 0;
        }
    }
   
    updateFooter() {
        if (this.serviceNote.serviceId != null) {
            this._footerobj.userid = this.serviceNote.creatorUserId;
            if (!this._footerobj.userid)
                this._footerobj.userid = this.appSession.userId;
            try { this._footerobj.createdon = this.serviceNote.createdOn.toDate(); } catch { }
            try { this._footerobj.modifiedon = this.serviceNote.modifiedOn.toDate(); } catch { }
            try { this._footerobj.modifiedid = this.serviceNote.lastModifierUserId; } catch { }
            this.cd.detectChanges();
            try { this.commonfootermodalchild.inputfooterData = this._footerobj; } catch { }
        }
    }

    getServiceNoteById(): void {
        this._serviceNotesServiceProxy.getServiceNoteByIdForViewOrEdit(this.serviceNoteId).
            pipe(finalize(() => { })).
            subscribe(result => {

                if (result != null) {
                    this.serviceNote = result;
                    this.clientInfo.clientName = this.serviceNote.patientName;
                    this.clientInfo.clientID = this.serviceNote.patientsId;
                    this.getProgramsByClientId();
                    if (this.serviceNote.serviceStartDateTime.year() != 0) {
                        this.startTime = new Date(moment(this.serviceNote.serviceStartDateTime).format('YYYY-MM-DDTHH:mm:ss'));
                    }

                    if (this.serviceNote.serviceEndDateTime.year() != 0) {
                        this.endTime = new Date(moment(this.serviceNote.serviceEndDateTime).format('YYYY-MM-DDTHH:mm:ss'));
                    }

                    this.totalHours = moment(this.endTime).diff(moment(this.startTime), 'minutes') / 60;

                    this.selectShift(this.serviceNote.shiftId);
                    this.updateFooter();
                }
            });
    }

    selectShift(shiftId) {
        for (let i = 0; i < this.shiftList.length; i++) {
            if (this.shiftList[i].id == shiftId) {
                this.selectedShift = this.shiftList[i];
                break;
            }
        }
    }

    getProgramsByClientId() {

        if (this.serviceNote.patientsId == 0 || this.serviceNote.patientsId == undefined) {
            return false;
        }
        this.programLoading = true;
        this.programEnrollList = [];
        this.siteOccupancy = "";
        if (this.mode == 'new') {
            this.serviceNote.siteName = "";
        }
        this._patientsServiceProxy.onPatientSelect(this.serviceNote.patientsId).subscribe(result => {
            this.programEnrollList = result.patients.programEnrollInfo;
            if (this.programEnrollList.length == 1 && this.mode == 'new') {
                this.selectedProgram = this.programEnrollList[0];
                this.onProgramChange(this.selectedProgram);
            }
            this.programLoading = false;
            if (this.mode != "new") {
                for (let i = 0; i < this.programEnrollList.length; i++) {
                    if (this.serviceNote.programDeptMappingId == this.programEnrollList[i].program) {
                        this.selectedProgram = this.programEnrollList[i];
                        break;
                    }
                }

                for (let i = 0; i < this.servicesList.length; i++) {
                    if (this.serviceNote.serviceId == this.servicesList[i].id) {
                        this.selectedService = this.servicesList[i];
                        break;
                    }
                }
            }
        });

    }

    searchClient(event: any) {
        var trimmed = this.clientInfo.clientName.trim();
        if (trimmed.length == 0) {
            this.form.reset();
            this.isLoading = false;
            this.clientData = null;
        }
        let wordSearch = this.clientInfo.clientName.trim();
        setTimeout(() => {
            if (wordSearch == this.clientInfo.clientName.trim()) {
                if (this.clientInfo.clientName.trim()) {
                    this.isLoading = true
                    this._commonServiceProxy.getClientInfo(wordSearch)
                        .pipe(finalize(() => { this.isLoading = false; }))
                        .subscribe(data => {
                            this.isLoading = false;
                            this.clientData = data;
                        })
                } else { }
            }
        }, 1000);
    }

    onClientSelect(event) {

        this.selectedProgram = null;
        this.onProgramChange(this.selectedProgram);

        this.selectedService = null;
        this.onServiceChange(this.selectedService);

        this.selectedShift = null;
        this.onShiftChange(this.selectedShift);


        this.resetErrorInfo();
        this.setClientInfo(event);
    }

    getPCPGoalsByClientIdAndProgramDeptMappingId() {
        this.serviceNote.pcpGoals = "";
        if (this.serviceNote.patientsId == 0 || this.programEnrollmentId == 0) {
            return false;
        }
        this._serviceNotesServiceProxy.getPCPGoalsByClientId(this.serviceNote.patientsId, this.programEnrollmentId).
            pipe(finalize(() => { })).
            subscribe(result => {
                this.serviceNote.pcpGoals = result;
            });
    }

    getAllServicesForDropDownList() {
        this._ltssServices.getAllServices(0).
            pipe(finalize(() => { })).
            subscribe(result => {
                //remove retainer service from list
                result = result.filter(r => r.serviceIdentifier != this._RETAINER_SERVICE_ID);
                result = result.filter(r => r.serviceIdentifier != this._DAY_PROGRAM_ID);
                this.servicesList = result;
                this.servicesListBackup = result;
            });
    }

    getEnrolledServicesByProgramId() {
        this.servicesLoading = true;
        this.noEnrolledServices = false;
        this._serviceEnrollmentsServiceProxy.getEnrolledServicesForDropDownListV2(this.serviceNote.patientsId, this.programEnrollmentId, this.serviceNote.serviceStartDateTime).
            pipe(finalize(() => { })).
            subscribe(result => {
                if (result != null) {
                    result = result.filter(r => r.serviceIdentifier != this._RETAINER_SERVICE_ID);
                    this.enrolledServicesList = result;
                    this.servicesLoading = false
                    if (this.enrolledServicesList.length == 0) {
                        this.noEnrolledServices = true;
                        this.notEnrolledServices();
                    } else {
                        this.enrolledServices();
                    }
                } else {
                    this.servicesLoading = false
                    this.noEnrolledServices = true;
                    this.notEnrolledServices();
                }
            });
    }

    goToClientProfile(editMode): void {
        if (this.serviceNote.patientsId) {
            this._router.navigate(['/app/client/patients/create-or-edit-patients-modal/', this.serviceNote.patientsId, editMode]);
        }
    }

    enrolledServices() {
        this.servicesList = []
        for (let i = 0; i < this.enrolledServicesList.length; i++) {
            if (this.serviceNote.programDeptMappingId == this.enrolledServicesList[i].programDeptMappingId) {
                let dto = new GetAllServiecsForDropdownListDto();
                dto.serviceName = this.enrolledServicesList[i].serviceName;
                dto.serviceIdentifier = this.enrolledServicesList[i].serviceIdentifier;
                dto.id = this.enrolledServicesList[i].serviceId;
                dto.programDeptMappingId = this.enrolledServicesList[i].programDeptMappingId;
                this.servicesList.push(dto);
            }
        }

        if (this.servicesList.length == 1) {
            this.selectedService = this.servicesList[0];
            this.serviceNote.serviceId = this.servicesList[0].id;
        }
    }

    notEnrolledServices() {
        this.servicesList = [];
        for (let i = 0; i < this.servicesListBackup.length; i++) {
            if (this.serviceNote.programDeptMappingId == this.servicesListBackup[i].programDeptMappingId) {
                let dto = new GetAllServiecsForDropdownListDto();
                dto.serviceName = this.servicesListBackup[i].serviceName;
                dto.serviceIdentifier = this.servicesListBackup[i].serviceIdentifier;
                dto.id = this.servicesListBackup[i].id;
                dto.programDeptMappingId = this.servicesListBackup[i].programDeptMappingId;
                this.servicesList.push(dto);
            }
        }

        if (this.servicesList.length == 1) {
            this.selectedService = this.servicesList[0];
            this.serviceNote.serviceId = this.servicesList[0].id;
        }
    }

    setClientInfo(data): void {
        this.clientInfo.clientID = data.clientID;
        this.serviceNote.patientsId = data.clientID;
        this.clientInfo.clientName = data.clientName;
        this.clientData = [];
        if (data.clientID != undefined) {
            this.getProgramsByClientId();
            if (this.serviceNote.programDeptMappingId > 0) {
                this.getPCPGoalsByClientIdAndProgramDeptMappingId();
            }
        }
    }

    onClientClear(event): void {
        this.setClientInfo(event);
    }

    save() {
        this.resetErrorInfo();
        if (this.serviceNote.patientsId == 0) {
            this.isError = true;
            this.errorDescription = "Please select client";
            return false;
        }

        if (this.serviceNote.programDeptMappingId == 0) {
            this.isError = true;
            this.errorDescription = "Please select program";
            return false;
        }
        if (this.serviceNote.serviceId == 0) {
            this.isError = true;
            this.errorDescription = "Please select " + this.selectedProgram.deptName + " Service";
            return false;
        }

        if (this.serviceNote.shiftId == 0) {
            this.isError = true;
            this.errorDescription = "Please select Shift";
            return false;
        }

        if (this.startTime == undefined) {
            this.isError = true;
            this.errorDescription = "Please select Start Date and Time";
            return false;
        }

        if (this.endTime == undefined) {
            this.isError = true;
            this.errorDescription = "Please select End Date and Time";
            return false;
        }

        if (this.serviceNote.clientStatus == undefined || this.serviceNote.clientStatus == "") {
            this.isError = true;
            this.errorDescription = "Please select Client Status";
            return false;
        }

        if (this.totalHours <= 0) {
            this.isError = true;
            this.errorDescription = "Total Hours must be more than zero, please enter positive value which is less than " + this.selectedShift.shiftHours + ", please enter valid value or change date and time";
            return false;
        }

        if (this.totalHours > this.selectedShift.shiftHours) {
            this.isError = true;
            this.errorDescription = "Total Hours must be  " + this.selectedShift.shiftHours + "  hours or less, please enter valid value or change date and time";
            return false;
        }


        if (this.serviceNote.serviceDescription.trim() == "") {
            this.isError = true;
            this.errorDescription = "Please enter Service Description";
            return false;
        }

        this.serviceNote.serviceStartDateTime = this.startTime;
        this.serviceNote.serviceEndDateTime = this.endTime;
        this.serviceNote.noteDate = this.startTime;

        this._serviceNotesServiceProxy.createOrEdit(this.serviceNote).
            pipe(finalize(() => { })).
            subscribe(result => {
                //send data to parent   
                this.serviceNote.id = result;
                this.serviceNote.createdBy = this.appSession.user.name;
                this.serviceNote.createdOn = moment();
                this.onNewNoteSave.emit(this.serviceNote);
            });
    }
    close() {
        this.onNoteCancel.emit();
    }

    editView() {
        if (this.serviceNote.serviceId != undefined) {
            this._router.navigate(['app/client/services-note', this.serviceNote.serviceId, "edit"]);
        }
    }

    onServiceChange(service: GetAllServiecsForDropdownListDto) {
        
        this.resetErrorInfo();
        if (service == null) {
            this.serviceNote.serviceId = 0;
        } else {
            this.serviceNote.serviceId = service.id;
        }
    }


    onProgramChange(prog: CreateOrEditProgramEnrollmentDto): void {

        this.resetErrorInfo();
        this.selectedShift = null;
        this.selectedService = null;

        this.programEnrollmentId = 0;
        this.serviceNote.siteName = "";
        this.serviceNote.programDeptMappingId = 0;
        this.serviceNote.siteOccupancy = 0;
        this.serviceNote.siteId = 0;
        this.serviceNote.serviceId = 0;
        this.serviceNote.shiftId = 0;

        if (prog != null) {
            this.programEnrollmentId = prog.id;
            this.serviceNote.siteName = prog.treatmentSiteName;
            this.serviceNote.programDeptMappingId = prog.program;
            this.serviceNote.siteOccupancy = prog.siteOccupancy;
            this.serviceNote.siteId = prog.siteId;

            this.serviceNote.pcpGoals = "";
            this.getPCPGoalsByClientIdAndProgramDeptMappingId();
            this.getEnrolledServicesByProgramId();

            if (this.serviceNote.programDeptMappingId == 1 || this.serviceNote.programDeptMappingId == 3) {
                for (let i = 0; i < this.shiftList.length; i++) {
                    if (this.shiftList[i].program == "Day") {
                        this.selectedShift = this.shiftList[i];
                        this.onShiftChange(this.selectedShift);
                        break;
                    }
                }
            }
        }
    }

    onShiftChange(shift) {
        this.resetErrorInfo();
        if (shift == null) {
            this.serviceNote.shiftId = 0;
        } else {
            this.serviceNote.shiftId = shift.id;
            this.shiftStartTime = shift.startTime;

            //fix following code for iOS safari browser
            this.startTime = new Date(moment(moment(this.startTime).format('YYYY-MM-DD') + " " + shift.startTime).format('YYYY-MM-DDTHH:mm:ss'));
            this.endTime = new Date(moment(moment(this.startTime).add(shift.shiftHours * 60, 'minutes').toDate()).format('YYYY-MM-DDTHH:mm:ss'));
            this.totalHours = moment(this.endTime).diff(moment(this.startTime), 'minutes') / 60;
        }
    }

    timeSelected(event): void {
        this.resetErrorInfo();
        this.totalHours = 0;
        if (event.element.id == "ejstartDateTime") {
            this.startTime = new Date(moment(moment(event.value).format('YYYY-MM-DD') + " " + this.selectedShift.startTime).format('YYYY-MM-DDTHH:mm:ss'));
            this.serviceNote.noteDate = this.startTime;
            this.serviceNote.serviceStartDateTime = this.startTime;
            if (this.selectedShift.shiftHours > 0) {
                this.endTime = new Date(moment(moment(this.startTime).add(this.selectedShift.shiftHours * 60, 'minutes').toDate()).format('YYYY-MM-DDTHH:mm:ss'));
            }
        }

        if (event.element.id == "ejstartDateTime" && this.endTime) {
            this.totalHours = moment(this.endTime).diff(moment(this.startTime), 'minutes') / 60;
        }
        else {
            if (this.startTime)
                this.totalHours = moment(event.value).diff(moment(this.startTime), 'minutes') / 60;
        }
        if (this.totalHours > this.selectedShift.shiftHours) {
            this.isError = true;
            this.errorDescription = "Total Hours must be " + this.selectedShift.shiftHours + " hours or less, please enter valid value or change date and time";
        }
    }

    totalHoursChange(event) {
        this.resetErrorInfo();
        if (this.totalHours > this.selectedShift.shiftHours) {
            this.isError = true;
            this.errorDescription = "Total Hours must be  " + this.selectedShift.shiftHours + "  hours or less, please enter valid value or change date and time";
        } else {
            this.endTime = new Date(moment(moment(this.startTime).add(parseFloat(event.target.value) * 60, 'minutes').toDate()).format('YYYY-MM-DDTHH:mm:ss'));
        }
    }

    dateCleared(elementId) {

        this.totalHours = 0;
        if (elementId == "ejstartDateTime") {
            this.startTime = undefined;
            this.serviceNote.serviceStartDateTime = this.startTime;
        } else {
            this.endTime = undefined;
            this.serviceNote.serviceEndDateTime = this.endTime;
        }
    }

    serviceDescriptionChange(): void {
        this.resetErrorInfo();
    }
    resetErrorInfo(): void {
        this.isError = false;
        this.errorDescription = "";
    }

    deleteServiceNote(): void {
        this.message.confirm(
            this.l('Are you sure you want to delete Service Note for Client: ' + this.serviceNote.patientName + ' ?'), ' ',
            (isConfirmed) => {
                if (isConfirmed) {
                    this._serviceNotesServiceProxy.delete(this.serviceNoteId)
                        .subscribe(() => {
                            this.close();
                            this.notify.success('Service Note Successfully Deleted', "", { position: ['middle', 'right'] });
                        });
                }
            }

        );
    }
    createServiceNote() {
        this.mode = "edit";
        this._view = false;
        this._router.navigate(['app/client/services-notes', 0, "new"]);
    }

    showPCPGoalsInDialog(): void {
        if (this.serviceNote.pcpGoals) {
            this.displayPCPGoalsInDialog = true;
        }
    }

    UpdateComments(comments): void {
        this.serviceNote.serviceDescription = comments
    }
   
}
